









import { Props } from "./types"
import { defineComponent, onMounted } from "@vue/composition-api"
import { v4 as uuidV4 } from "uuid"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"

const propsOptions = {
  scopes: {
    type: String,
    default: "",
  },
  clientId: {
    type: String,
  },
}

export default defineComponent({
  props: propsOptions,
  components: {
    I18nFormattedMessage,
  },
  setup(props: Props) {
    const { getContext } = teamsContextContainer.useContainer()
    onMounted(async () => {
      const context = await getContext()
      const tenantId = `${context.tid}`
      const graphScopes = props.scopes
        ? props.scopes
        : [
            "Tasks.ReadWrite",
            "Files.ReadWrite.All",
            "Calendars.ReadWrite",
            "User.Read",
            "User.ReadBasic.All",
            "Sites.ReadWrite.All",
            "TeamMember.Read.All",
            "Team.ReadBasic.All",
            "Channel.ReadBasic.All",
          ]
            .map(s => `https://graph.microsoft.com/${s}`)
            .join(" ")

      const queryParams = {
        tenant: tenantId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        client_id: props.clientId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        response_type: "code",
        scope: `${graphScopes}`,
        // eslint-disable-next-line @typescript-eslint/camelcase
        redirect_uri: window.location.origin + "/authorize-end",
        nonce: uuidV4(),
      }

      const url = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize`
      const queryString = new URLSearchParams(queryParams).toString()

      window.location.assign(`${url}?${queryString}`)
    })
  },
})
